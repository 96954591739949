// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_IN_BAZAAR = "Zobraz \u010fal\u0161ie ko\u010d\u00edky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_SCORE = "Zobraz \u010fal\u0161ie ko\u010d\u00edky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_HAPPY_MEDIUM = "Zobraz \u010fal\u0161ie Zlat\u00fd stred ko\u010d\u00edky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_POPULAR = "Zobraz \u010fal\u0161ie ko\u010d\u00edky za babku (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_TOP = "Zobraz \u010fal\u0161ie TOP ko\u010d\u00edky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_HAPPY_MEDIUM = "Zlat\u00fd stred";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_IN_BAZAAR = "Najlep\u0161ie v baz\u00e1ri";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_POPULAR = "Za babku";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_SCORE = "Najlep\u0161ie hodnoten\u00e9";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_TOP = "TOPky";
