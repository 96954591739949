// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ARTICLE_SECTIONS = "Rubriky \u010dl\u00e1nkov";
export const STROLLEROPEDIA_MORE_ARTICLES = "Zobraz \u010fal\u0161ie \u010dl\u00e1nky";
export const STROLLEROPEDIA_NEW_ARTICLES_LONG = "Najnov\u0161ie \u010dl\u00e1nky v ko\u010d\u00edkop\u00e9dii";
export const STROLLERS_HOME_ADD_NEW_QUESTION = "Pozri ko\u010d\u00edkov\u00fa porad\u0148u";
export const STROLLERS_HOME_COVER_DESCRIPTION = "Ko\u010d\u00edkop\u00e9dia je najv\u00e4\u010d\u0161ia encyklop\u00e9dia ko\u010d\u00edkov na svete";
export const STROLLERS_HOME_NEW_QUESTIONS_IN_COUNSELLING = "Nov\u00e9 ot\u00e1zky v poradni";
export const STROLLERS_HOME_NEW_REVIEWS = "Nov\u00e9 hodnotenia od mami\u010diek";
export const STROLLERS_HOME_STROLLERS_OF_year = "Najnov\u0161ie modely ko\u010d\u00edkov %(year)s";
export const STROLLERS_HOME_TIPS_FROM_MUMS = "Tipy na ko\u010d\u00edky od n\u00e1kupn\u00fdch poradk\u00fd\u0148";
export const STROLLER_STROLLEROPEDIA = "Ko\u010d\u00edkop\u00e9dia";
