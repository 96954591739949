// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const counter_RATINGS = ["%(counter)s hodnotenie", "%(counter)s hodnotenia", "%(counter)s hodnoten\u00ed"];
export const STROLLER_CARD_AIRLESS_WHEELS = "koles\u00e1 nenafukovacie s v\u00fdpl\u0148ou";
export const STROLLER_CARD_AIR_WHEELS = "nafukovacie koles\u00e1";
export const STROLLER_CARD_BIG_SHOPPING_BASKET = "ve\u013ek\u00fd \u00falo\u017en\u00fd priestor";
export const STROLLER_CARD_CARRYCOT_DIMS_SPACED = "ve\u013ek\u00e1 vani\u010dka";
export const STROLLER_CARD_CAR_SEAT_CAN_BE_ATTACHED = "mo\u017enos\u0165 pripojenia autoseda\u010dky";
export const STROLLER_CARD_COMPARE = "Porovnaj";
export const STROLLER_CARD_COMPARE_REMOVE = "Odober";
export const STROLLER_CARD_FOAM_WHEELS = "penov\u00e9 koles\u00e1";
export const STROLLER_CARD_FOLDABLE_CARRYCOT = "skladacia vani\u010dka";
export const STROLLER_CARD_MANUAL_BRAKE_PRESENT = "brzda na rukov\u00e4ti";
export const STROLLER_CARD_MAXIMUM_LOAD_PER_CHILD_IN_kg = "nosnos\u0165 seda\u010dky %(kg)s kg";
export const STROLLER_CARD_ONE_HAND_FOLDING = "skladanie jednou rukou";
export const STROLLER_CARD_PLASTIC_WHEELS = "plastov\u00e9 koles\u00e1";
export const STROLLER_CARD_REAR_WHEELS_DIAMETER_IN_cm = "zadn\u00e9 koles\u00e1 \u2300%(cm)s cm";
export const STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM = "Zlat\u00fd stred";
export const STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR = "Za babku";
export const STROLLER_CARD_RECOMMENDED_CHOICE_TOP = "TOP";
export const STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED = "fixn\u00e9";
export const STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING = "oto\u010dn\u00e9";
export const STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE = "oto\u010dn\u00e9 s mo\u017enos\u0165ou fix\u00e1cie";
export const STROLLER_CARD_ROTATING_SEAT = "oto\u010dn\u00e9 sedadlo";
export const STROLLER_CARD_SPACIOUS_SEAT = "priestrann\u00e9 sedadlo";
export const STROLLER_CARD_UNFOLDED_DIMENSIONS_IN_cm_WIDTH = "\u0161\u00edrka ko\u010d\u00edka %(cm)s cm";
export const STROLLER_CARD_VERTICAL_SEAT  = "Chrbtov\u00e1 opierka ide polohova\u0165 do kolm\u00e9ho sedu (90\u00b0)";
export const STROLLER_CARD_VERY_GOOD_FOLDING = "velmi skladn\u00fd";
export const STROLLER_CARD_WEIGHT_IN_kg = "hmotnos\u0165 ko\u010d\u00edka %(kg)s kg";
export const STROLLER_CARD_WEIGHT_IS_LOW = "velmi \u013eahk\u00fd";
export const STROLLER_CARD_WHEELS = "koles\u00e1";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT = "vhodn\u00fd pre n\u00edzkych rodi\u010dov";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL = "vhodn\u00fd pre vysok\u00fdch rodi\u010dov";
export const STROLLER_UNPREPARED_TAG = "Pripravujeme";
